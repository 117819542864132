.page-index-section.despre-noi {
  height: var(--content-height);
  width: 100%;
  position: relative;
}

.page-index-section.despre-noi .cover {
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.page-index-section.despre-noi .cover img {
  object-fit: cover;
  width: 100%;
}

.page-index-section.despre-noi .content {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page-index-section.despre-noi .motto {
  text-align: right;
  padding-top: var(--gap-big);
  padding-right: var(--gap-big);
  font-size: 2em;
  color: var(--color-fasmr-blue);
}

.page-index-section.despre-noi .motto b {
  font-weight: 800;
}

.page-index-section.despre-noi .text-container {
}
