.navigation-menu-section.despre-noi {
  --section-gap-small: 1vh;
  --section-gap-medium: 1.5vh;
  --section-gap-big: 2.5vh;

  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: var(--section-gap-big);

  flex-grow: 1;

  color: var(--dark);
  font-size: 1em;
  font-weight: 300;
  margin-left: 1%;
}

.navigation-menu-section.despre-noi a {
  text-decoration: none;
  color: inherit;
}

.navigation-menu-section.despre-noi a:hover,
.navigation-menu-section.despre-noi td:hover {
  color: var(--color-fasmr-blue);
  background-color: rgba(0, 0, 0, 0.03);
  transition: none;
}

.navigation-menu-section.despre-noi .tab-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  border-bottom-left-radius: var(--border-radius-small);
  overflow: hidden;
}

.navigation-menu-section.despre-noi .tab-bar .tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  padding: var(--section-gap-medium) 0;
}

.navigation-menu-section.despre-noi .tables-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  flex-grow: 1;
}

.navigation-menu-section.despre-noi .tables-container .title {
  font-weight: bold;
  padding: var(--section-gap-small) 0;
  text-align: center;
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}

.navigation-menu-section.despre-noi .table-container {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  margin: 0 var(--section-gap-big);
}

.navigation-menu-section.despre-noi .asociatii-table-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.navigation-menu-section.despre-noi .asociatii-table {
  border-radius: var(--border-radius-small);
  overflow: hidden;
}

.navigation-menu-section.despre-noi .asociatii-table td {
  text-align: center;
  padding: var(--section-gap-big) 0;
}

.navigation-menu-section.despre-noi .asociatii-table td:first-child {
  text-align: center;
  padding-left: var(--section-gap-small);
}

.navigation-menu-section.despre-noi .asociatii-table td:last-child {
  text-align: center;
  padding-right: var(--section-gap-small);
}

.navigation-menu-section.despre-noi .right.table-container {
  align-items: stretch;
}

.navigation-menu-section.despre-noi .right-contents-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
}

.navigation-menu-section.despre-noi .right-columns-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  border-radius: var(--border-radius-small);
  overflow: hidden;
}

.navigation-menu-section.despre-noi .right-columns {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
}

.navigation-menu-section.despre-noi .right-columns .column-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  flex-grow: 1;
}

.navigation-menu-section.despre-noi .comitete-column,
.navigation-menu-section.despre-noi .divizii-column {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  flex-grow: 1;
}

.navigation-menu-section.despre-noi .comitete-column,
.navigation-menu-section.despre-noi .comitet {
  text-align: center;
}

.navigation-menu-section.despre-noi .divizii-column,
.navigation-menu-section.despre-noi .divizie {
  text-align: center;
}

.navigation-menu-section.despre-noi .right-columns .header {
  font-weight: 500;
  flex-grow: 0;
}

.navigation-menu-section.despre-noi .comitet,
.navigation-menu-section.despre-noi .divizie,
.navigation-menu-section.despre-noi .cd {
  flex-grow: 1;
  padding: var(--section-gap-small) 0;
}

.navigation-menu-section.despre-noi .comitet {
  padding-right: var(--section-gap-medium);
}

.navigation-menu-section.despre-noi .divizie {
  padding-left: var(--section-gap-medium);
}

.navigation-menu-section.despre-noi .cd-separator-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  width: 75%;
}

.navigation-menu-section.despre-noi .cd {
  flex-grow: 1;
  text-align: center;
  font-weight: 500;
}
