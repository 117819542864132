@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  --color-fasmr-blue: #3090d8;
  --color-dark: #474747;
  --color-light: #f4f4f4;
  --color-gray-dark: #d0d0d0;
  --color-gray: #e0e0e0;
  --color-gray-light: #ececec;

  --gap-small: 12px;
  --gap-medium: 26px;
  --gap-big: 40px;

  --border-radius-small: 12px;

  --box-shadow-small: 0 3px 8px 0px rgba(0, 0, 0, 0.4);
  --drop-shadow-small: 0 4px 4px rgba(0, 0, 0, 0.4);

  --font-primary: "Montserrat";
  font-family: var(--font-primary);

  transition: all 0.2s ease;
  z-index: 1;

  --nav-height: 45px;
  --content-height: calc(100vh - var(--nav-height) - 2 * var(--gap-small));
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.stretcher {
  display: flex;
  flex-grow: 1;
}

img {
  object-fit: cover;
  display: block;
}

img.h {
  height: 100%;
  width: auto;
}

img.w {
  width: 100%;
  height: auto;
}

.fill-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;

  background-color: var(--color-fasmr-blue);
  color: white;

  border: 2px solid transparent;
  border-radius: var(--border-radius-small);
}

.fill-button:hover {
  background-color: transparent;
  border: 2px solid var(--color-fasmr-blue);
  color: var(--color-fasmr-blue);
}

button {
  cursor: pointer;
}

.transparent {
  background-color: transparent;
}
