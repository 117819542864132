.xo-table td {
  position: relative;
}

.xo-table tr:not(:last-child) td::before {
  content: "";
  position: absolute;
  bottom: calc(-1 * var(--border-width));
  height: var(--border-width);
  left: 0;
  right: 0;
  background-color: var(--border-color);
}

.xo-table tr:not(:last-child) td:not(:last-child)::before {
  right: calc(-1 * var(--border-width));
}

.xo-table tr:not(:last-child) td:first-child::before {
  left: var(--space);
}

.xo-table tr:not(:last-child) td:last-child::before {
  right: var(--space);
}

.xo-table td:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(-1 * var(--border-width));
  width: var(--border-width);
  background-color: var(--border-color);
}

.xo-table tr:first-child td:not(:last-child)::after {
  top: var(--space);
}

.xo-table tr:last-child td:not(:last-child)::after {
  bottom: var(--space);
}
