.generic-error {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;

  justify-content: center;
  align-items: center;

  font-size: 1.5em;
}

.generic-error h1 {
  font-size: 4em;
  margin: 0;
  padding: 0;
}
