.navigation-menu {
  --offset-left: calc(134px - 1.04em);
  --offset-top: calc(var(--nav-height) + 2 * var(--gap-small) - 16px);

  position: absolute;
  top: var(--offset-top);
  left: var(--offset-left);
  z-index: 2;

  width: calc(100% - 2 * var(--offset-left));
}

.navigation-menu > .container {
  width: 100%;
  overflow: hidden;

  background-color: var(--color-light);

  border-radius: var(--border-radius-small);

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  color: var(--color-dark);
  font-size: 1.2em;
  height: 50vh;
}

@media screen and (max-height: 790px) {
  .navigation-menu > .container {
    height: calc(100vh - 2 * var(--offset-top));
  }
}

@media screen and ((max-height: 450px) or (max-width: 1050px)) {
  .navigation-menu {
    font-size: 0.7em;
  }
  .navigation-menu > .container {
    height: calc(100vh - var(--offset-top) - var(--gap-medium));
  }
}

@media screen and ((max-height: 350px) or (max-width: 770px)) {
  .navigation-menu {
    font-size: 0.5em;
  }
}

.navigation-menu .left-list,
.navigation-menu .right-side {
  position: relative;
  display: flex;
}

.navigation-menu .left-list {
  width: 30%;
  background-color: var(--color-gray-light);
  flex-grow: 1;
  padding: var(--gap-small) 0;
}

.navigation-menu .overlay-graphics {
  position: absolute;
  z-index: 2;
  top: -4px;
  left: calc(0.95em + 10px);
  width: 3px;
  height: calc(5.2em + 100px);
  background-color: var(--color-fasmr-blue);
}

.navigation-menu .left-list .items-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  flex-grow: 1;

  width: 100%;
}

.navigation-menu .left-list .items-container .left-list-item {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin: 0px;
  padding: var(--gap-small);

  text-decoration: none;
  color: inherit;
  transition: all 0s;
  cursor: default;
}

.navigation-menu .left-list .items-container .left-list-item.active {
  color: var(--color-fasmr-blue);
  font-weight: bold;
  background-color: var(--color-light);
  --pseudo-border-radius: var(--border-radius-small);
}

.navigation-menu .left-list .items-container .left-list-item .pseudo-border {
  position: absolute;
  right: 0;
  width: calc(2 * var(--pseudo-border-radius));
  height: calc(2 * var(--pseudo-border-radius));
  background-color: var(--color-light);
}

.navigation-menu
  .left-list
  .items-container
  .left-list-item
  .pseudo-border.top-right {
  top: calc(-2 * var(--pseudo-border-radius));
}

.navigation-menu
  .left-list
  .items-container
  .left-list-item
  .pseudo-border.bottom-right {
  bottom: calc(-2 * var(--pseudo-border-radius));
}

.navigation-menu
  .left-list
  .items-container
  .left-list-item
  .pseudo-border
  .overlay {
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-light);
  overflow: hidden;
}

.navigation-menu
  .left-list
  .items-container
  .left-list-item
  .pseudo-border
  .overlay.top-right {
  border-bottom-right-radius: var(--pseudo-border-radius);
}

.navigation-menu
  .left-list
  .items-container
  .left-list-item
  .pseudo-border
  .overlay.bottom-right {
  border-top-right-radius: var(--pseudo-border-radius);
}

.navigation-menu .left-list .items-container .left-list-item .icon-container {
  height: 1.2em;
  margin-right: 10px;
}

.navigation-menu .right-side {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 70%;

  align-self: stretch;
}

.navigation-menu .right-side .background-graphics {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-30%);
  width: 70%;
  opacity: 0.5;
}

.navigation-menu .navigation-menu-section {
  display: none;
}

.navigation-menu .navigation-menu-section.active {
  display: flex;
}
